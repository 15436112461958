<div class="modify-text-message">
  <div class="tw-flex tw-flex-col tw-items-center tw-gap-3">
    <img [src]="shareService.EditSVG" alt="edit-icon" class="img-responsive">
    <h5>ویرایش متن پیامک</h5>
    <p class="mb-3">لطفا متن پیامک خود را وارد کنید.</p>
  </div>

  <div class="modal-body text-center">
    <app-text-builder (maxLenValidated)="validateMaxLength($event)"
                      (messageTextChanged)="messageTextChanged($event)"
                      [messageText]="text ? text : ''"
                      [textBuilderType]="textBuilderTypeEnum.Textarea">
    </app-text-builder>
  </div>

  <div class="modal-footer text-center">
    <button (click)="onSubmit()"
            [disabled]="!maxLengthValid || text.length === 0 || text === defaultText"
            class="btn success-btn">تایید
    </button>
    <button (click)="activeModal.dismiss()"
            class="btn dark-btn mr-3">بازگشت
    </button>
  </div>
</div>
