<div class="tw-flex tw-flex-col tw-items-center tw-gap-3">
  <img [src]="svg ? svg : sharedService.QuestionSVG">
  <!--  <h5 class="dark-gray-text">{{modalHeader}}</h5>-->
</div>
<div class="modal-body text-center">
  <p class="title text-center pre-wrap font-weight-normal">{{modalText}}</p>
</div>
<div class="modal-footer text-center">
  <button (click)="onSubmit()" class="btn success-btn">
    {{modalConfirmButton ? modalConfirmButton : 'تایید'}}
  </button>
  <button (click)="onCancel()" class="btn dark-btn mr-3">
    {{modalCancelButton ? modalCancelButton : 'بازگشت'}}
  </button>
</div>

