import {Injectable} from '@angular/core';
import {WeekdaysEnum} from "../../../../projects/cron-editor/src/lib/models/weekdays.enum";
import {CampaignTypeEnum} from "../enums/campaign-type.enum";
import {ProvidersEnum} from "../enums/providers.enum";
import {UserCompanyActivityFieldEnum} from "../enums/user-company-activity-field.enum";
import {UserCompanyTypeEnum} from "../enums/user-company-type.enum";
import {DeliveryStatusEnum} from "../enums/delivery-status.enum";
import {FilterItemModel} from '@shared/components/table/models/filter-item.model';
import {CampaignStatusEnum} from "@shared/enums/campaign-status.enum";
import {PaymentCaseEnum} from "@shared/enums/payment-case.enum";
import {
  CampaignItemEvaluationTypeEnum
} from "@app/modules/report/components/campaign-report/abtest-campaign-analysis/model/campaign-Item-evaluation-type.enum";
import {
  CooperationSettlementRequestStatusEnum
} from "@app/modules/sales-cooperation/enums/cooperation-settlement-request-status.enum";
import {SendStatusEnum} from "@shared/enums/send-status.enum";
import {ActionNameEnum} from "@shared/enums/action-name.enum";
import {SendMessageEnum} from "@shared/enums/send-message.enum";
import {CodeReaderImportRequestStatus} from "@shared/enums/code-reader.enum";
import {WhoIsStatusEnum} from "@shared/enums/who-is-status.enum";
import {ContextEnum} from "@shared/enums/app.enum";
import {EnvironmentType} from "@shared/enums/developer.enum";


@Injectable({
  providedIn: 'root'
})

export class SharedService {
  constructor() {
  }


  /*
  * setter functions
  */
  set tagList(value: FilterItemModel[]) {
    this.$tagList = value ?? [];
  }


  /*
  * getter functions
  */
  get tagList() {
    return this.$tagList;
  }


  get EditSVG() {
    return this.$EditSVG;
  }

  get QuestionSVG() {
    return this.$QuestionSVG;
  }

  get AddSVG() {
    return this.$AddSVG;
  }

  get EventSvg() {
    return this.$EventSVG;
  }

  get DeleteSVG() {
    return this.$DeleteSVG;
  }

  get LogoLoginSVG() {
    return this.$LogoLoginSVG;
  }

  get loginLogMethodOptions() {
    return this.$loginLogMethodOptions;
  }

  get invoiceType() {
    return this.$invoiceType;
  }

  get ticketStatus() {
    return this.$ticketStatus;
  }

  get fastSendTemplateStatus() {
    return this.$fastSendTemplateStatus;
  }

  get campaignType() {
    return this.$campaignType;
  }

  get sendType() {
    return this.$sendSmsType;
  }

  get sendSmsState() {
    return this.$sendSmsState;
  }

  get codeReaderStatus() {
    return this.$codeReaderStatus;
  }


  get codeReaderImportStatus() {
    return this.$codeReaderImportStatus;
  }


  get campaignStatus() {
    return this.$campaignStatus;
  }

  get provider() {
    return this.$providerShownName;
  }

  get creditTransactionType() {
    return this.$creditTransactionType;
  }

  get paymentStatus() {
    return this.$paymentStatus;
  }

  get lineStatus() {
    return this.$lineStatus;
  }

  get lineEarnMethod() {
    return this.$lineEarnMethod;
  }

  get lineType() {
    return this.$lineType;
  }

  get operator() {
    return this.$operator;
  }

  get userCompanyActivityField() {
    return this.$userCompanyActivityField;
  }

  get userCompanyType() {
    return this.$userCompanyType;
  }

  get deliveryStatus() {
    return this.$deliveryStatus;
  }

  get productType() {
    return this.$productType;
  }

  get whoIsStatus() {
    return this.$whoIsStatus;
  }

  get weekday() {
    return this.$weekday;
  }


  get persianRegex() {
    return this.$PersianRegex;
  }

  get completeInformation() {
    return this.$completeInformation;
  }

  get sendStatus() {
    return this.$sendStatus;
  }

  get actionName() {
    return this.$actionName;
  }

  get context() {
    return this.$context;
  }

  get apiEnvironment() {
    return this.$ApiEnvironment;
  }


  private $PersianRegex = /^[\u0600-\u06FF\s]+$/;

  private $LogoLoginSVG = '../../../assets/images/Company-sms.ir.svg';
  private $DeleteSVG = '../../../assets/images/icons/modal/trash.svg';
  private $EditSVG = '../../../assets/images/icons/modal/edit.svg';
  private $QuestionSVG = '../../../assets/images/icons/modal/question.svg';
  private $AddSVG = '../../../assets/images/icons/modal/add.svg';
  private $EventSVG = '../../../assets/images/icons/modal/calender.svg';


  private $tagList: FilterItemModel[] = [];

  private $loginLogMethodOptions: FilterItemModel[] = [
    {name: 'نام کاربری و رمز عبور', value: 1},
    {name: 'موبایل', value: 2},
    {name: 'فراموشی رمز عبور', value: 3},
  ];

  private $invoiceType: FilterItemModel[] = [
    // {name: 'فاکتور خرید', value: 1},
    // {name: 'فاکتور بازگشتی', value: 2},
    {name: 'صورت حساب', value: 1},
    {name: 'صورت حساب برگشت', value: 2},
  ];

  private $ticketStatus: FilterItemModel[] = [
    {name: 'باز', value: 1},
    {name: 'پاسخ ادمین', value: 2},
    {name: 'پاسخ کاربر', value: 3},
    {name: 'در حال بررسی', value: 4},
    {name: 'بسته', value: 5},
    {name: 'در انتظار پیگیری', value: 6},

  ];

  private $fastSendTemplateStatus: FilterItemModel[] = [
    {name: 'در حال بررسی', value: 1},
    {name: 'تایید', value: 2},
    {name: 'عدم تایید', value: 3},
  ];

  private $campaignType: FilterItemModel[] = [
    {name: 'زمان بندی شده', value: CampaignTypeEnum.scheduled},
    {name: 'رویدادی', value: CampaignTypeEnum.event},
    {name: 'AB Test', value: CampaignTypeEnum.abTest},
  ];

  private $sendSmsType: FilterItemModel[] = [
    {name: 'ارسال سریع', value: SendMessageEnum.FastSend},
    {name: 'تست ارسال سریع', value: SendMessageEnum.FastSendTest},
    {name: 'ارسال ساده', value: SendMessageEnum.BulkSimpleSend},
    {name: 'ارسال هوشمند پارامتریک', value: SendMessageEnum.ParametricSmartSend},
    {name: 'ارسال هوشمند متناظر', value: SendMessageEnum.MultipleTextSmartSend},
    {name: 'کمپین ساده', value: SendMessageEnum.SimpleCampaign},
    {name: 'کمپین دوره ای', value: SendMessageEnum.ScheduledCampaign},
    {name: 'کمپین رویدادی', value: SendMessageEnum.EventCampaign},
    {name: 'ارسال API', value: SendMessageEnum.ApiSend},
    {name: 'منشی پیامکی', value: SendMessageEnum.SmsSecretary},
    {name: 'انتقال پیامک', value: SendMessageEnum.SmsTransmission},
    {name: 'کمپین A-B Test', value: SendMessageEnum.ABTestCampaign},
    {name: 'بانک شماره', value: SendMessageEnum.MobileBank},
    {name: 'کدخوان', value: SendMessageEnum.CodeReader},
    {name: 'ارسال از دکل', value: SendMessageEnum.TowerSend},
    {name: 'ارسال سیستمی', value: SendMessageEnum.System},
  ];

  private $sendSmsState: FilterItemModel[] = [
    {name: 'آماده', value: 1},
    {name: 'در حال ارسال', value: 2},
    {name: 'بررسی صحت ارسال', value: 3},
    {name: 'پایان یافته', value: 4},
    {name: 'ناموفق', value: 5},
    {name: 'انتظار', value: 6},
    {name: 'رد شده', value: 7}
  ];

  private $providerShownName: FilterItemModel[] = [
    {name: 'مگفا', value: ProvidersEnum.Magfa},
    {name: 'رهیاب', value: ProvidersEnum.Rahyab},
    {name: 'ارمغان', value: ProvidersEnum.Armaqan},
    {name: 'آسانک', value: ProvidersEnum.Asanak},
    {name: 'آسیاتک', value: ProvidersEnum.AsiaTech},
    {name: 'نوآوران', value: ProvidersEnum.Noavaran},
    {name: 'جیرینگ', value: ProvidersEnum.Jiring},
    {name: 'شاتل', value: ProvidersEnum.Shatel},
    {name: 'آرین‌تل', value: ProvidersEnum.Ariantel},
  ];

  private $creditTransactionType: FilterItemModel[] = [
    {name: 'خرید اعتبار', value: 1},
    {name: 'ارسال پیامک', value: 2},
    {name: 'بازگشت اعتبار', value: 3},
    {name: 'انتقال از/به سامانه قدیم', value: 4},
    {name: 'اصلاح تعرفه', value: 5},
    {name: 'اصلاح اعتبار', value: 6},
  ];

  private $paymentStatus: FilterItemModel[] = [
    {name: 'منتظر', value: 1},
    {name: 'موفق', value: 2},
    {name: 'ناموفق', value: 3},
    {name: 'تاخیر', value: 4},
  ];

  private $lineStatus: FilterItemModel[] = [
    {name: 'درحال بررسی', value: 1},
    {name: 'غیرفعال', value: 2},
    {name: 'فعال', value: 3},
  ];

  private $codeReaderStatus: FilterItemModel[] = [
    {name: 'صحیح', value: 1},
    {name: 'تکراری', value: 2},
    {name: 'نادرست', value: 3},
  ];

  private $codeReaderImportStatus: FilterItemModel[] = [
    {name: 'ایجاد شده', value: CodeReaderImportRequestStatus.Created},
    {name: 'در حال پردازش', value: CodeReaderImportRequestStatus.Processing},
    {name: 'انجام شده', value: CodeReaderImportRequestStatus.Completed},
    {name: 'خطا در اجرا', value: CodeReaderImportRequestStatus.ExecutionError},
    {name: 'محدودیت پلن', value: CodeReaderImportRequestStatus.PlanLimitation},
  ];


  private $campaignStatus: FilterItemModel[] = [
    {name: 'فعال', value: CampaignStatusEnum.Active},
    {name: 'غیر فعال', value: CampaignStatusEnum.Deactive},
    {name: 'تمام شده', value: CampaignStatusEnum.Finished},
  ];

  private $lineEarnMethod: FilterItemModel[] = [
    {name: 'خط هدیه', value: 1},
    {name: 'خریداری شده', value: 2},
    {name: 'انتقالی', value: 3},
    {name: 'پلن', value: 4},
  ];

  private $lineType: FilterItemModel[] = [
    // {name: '----', value: 0},
    {name: 'تبلیغاتی', value: 1},
    {name: 'خدماتی', value: 2},
  ];

  private $operator: FilterItemModel[] = [
    {name: 'همراه اول', value: 1},
    {name: 'ایرانسل', value: 2},
    {name: 'رایتل', value: 3},
    {name: 'تالیا', value: 4},
  ];

  private $userCompanyActivityField: FilterItemModel[] = [
    {name: 'بانک/موسسه مالی', value: UserCompanyActivityFieldEnum.Bank},
    {name: 'خدماتی', value: UserCompanyActivityFieldEnum.Service},
    {name: 'تولیدی', value: UserCompanyActivityFieldEnum.Manufacturing},
    {name: 'تبلیغاتی', value: UserCompanyActivityFieldEnum.Advertising},
    {name: 'بیمه', value: UserCompanyActivityFieldEnum.Insurance},
    {name: 'رسانه/خبرگزاری', value: UserCompanyActivityFieldEnum.Media},
    {name: 'فناوری اطلاعات', value: UserCompanyActivityFieldEnum.IT},
    {name: 'فرهنگی/ورزشی', value: UserCompanyActivityFieldEnum.SportsCultural},
    {name: 'سایر', value: UserCompanyActivityFieldEnum.Other},
  ];


  private $userCompanyType: FilterItemModel[] = [
    {name: 'سهامی خاص', value: UserCompanyTypeEnum.PrivateStock},
    {name: 'سهامی عام', value: UserCompanyTypeEnum.PublicStock},
    {name: 'مسوولیت محدود', value: UserCompanyTypeEnum.LimitedLiability},
    {name: 'تضمانی', value: UserCompanyTypeEnum.GeneralPartnership},
    {name: 'دولتی', value: UserCompanyTypeEnum.StateOwned},
    {name: 'وزارتخانه', value: UserCompanyTypeEnum.Ministry},
    {name: 'سفارتخانه', value: UserCompanyTypeEnum.Embassy},
    {name: 'مسجد', value: UserCompanyTypeEnum.Mosque},
    {name: 'مدرسه', value: UserCompanyTypeEnum.School},
    {name: 'NGO', value: UserCompanyTypeEnum.NGO},
    {name: 'موسسه', value: UserCompanyTypeEnum.Institute},
    {name: 'سایر', value: UserCompanyTypeEnum.Other},
  ];

  private $deliveryStatus: FilterItemModel[] = [
    {name: 'رسیده', value: DeliveryStatusEnum.Delivered},
    {name: 'نرسیده به گوشی', value: DeliveryStatusEnum.Undelivered},
    {name: 'رسیده به مخابرات', value: DeliveryStatusEnum.ReachedTheOperator},
    {name: 'نرسیده به مخابرات', value: DeliveryStatusEnum.NotReachedTheOperator},
    {name: 'رسیده به اپراتور', value: DeliveryStatusEnum.ReachedTheProvider},
    {name: 'ناموفق', value: DeliveryStatusEnum.Failed},
    {name: 'لیست سیاه', value: DeliveryStatusEnum.BlackList},
    {name: 'نامشخص', value: DeliveryStatusEnum.Unknown},
  ];

  private $weekday: FilterItemModel[] = [
    {name: 'شنبه', value: WeekdaysEnum.Saturday},
    {name: 'یکشنبه', value: WeekdaysEnum.Sunday},
    {name: 'دوشنبه', value: WeekdaysEnum.Monday},
    {name: 'سه شنبه', value: WeekdaysEnum.Tuesday},
    {name: 'چهارشنبه', value: WeekdaysEnum.Wednesday},
    {name: 'پنجشنبه', value: WeekdaysEnum.Thursday},
    {name: 'جمعه', value: WeekdaysEnum.Friday},
  ];

  private $productType: FilterItemModel[] = [
    {name: 'خط', value: PaymentCaseEnum.Line},
    {name: 'پیش فاکتور', value: PaymentCaseEnum.Proforma},
    {name: 'شارژ دستی', value: PaymentCaseEnum.CustomCredit},
    {name: 'پکیج', value: PaymentCaseEnum.Packages},
    {name: 'پلن ها', value: PaymentCaseEnum.Plans},
    {name: 'ارسال از دکل', value: PaymentCaseEnum.Tower},
    {name: 'ارزش افزوده', value: PaymentCaseEnum.Official},
  ];

  private $campaignItemEvaluation: FilterItemModel[] = [
    {name: 'بازخورد خوبی نداشت', value: CampaignItemEvaluationTypeEnum.DidNotHaveGoodFeedBack},
    {name: 'آمار خاصی بدست نیاوردیم', value: CampaignItemEvaluationTypeEnum.NoStatistics},
    {name: 'نسبتا خوب بود', value: CampaignItemEvaluationTypeEnum.FairlyGood},
    {name: 'از همه تست‌ها بهتر بود', value: CampaignItemEvaluationTypeEnum.TheBestTest},
    {name: 'بالای 50%', value: CampaignItemEvaluationTypeEnum.MoreThan50},
    {name: 'بالای 75%', value: CampaignItemEvaluationTypeEnum.MoreThan75},
    {name: 'پایین 50%', value: CampaignItemEvaluationTypeEnum.LessThan50},
    {name: 'موفقیت‌آمیز بود', value: CampaignItemEvaluationTypeEnum.Successful},
    {name: 'با شکست روبه‌رو شد', value: CampaignItemEvaluationTypeEnum.Failed},
    {name: 'از همه تست‌ها بدتر بود', value: CampaignItemEvaluationTypeEnum.TheWorstTest},
  ];

  private $whoIsLineState: FilterItemModel[] = [
    {name: 'فعال', value: true},
    {name: 'غیرفعال', value: false},
  ];


  private $whoIsStatus: FilterItemModel[] = [
    {name: 'عدم ثبت اطلاعات', value: WhoIsStatusEnum.NoWhois},
    {name: 'تایید شده', value: WhoIsStatusEnum.Approved},
    {name: 'رد شده', value: WhoIsStatusEnum.Rejected},
    {name: 'در حال بررسی وضعیت', value: WhoIsStatusEnum.StatusCheck},
    {name: 'منقضی شده', value: WhoIsStatusEnum.Expired},
    {name: 'در انتظار استعلام', value: WhoIsStatusEnum.PendingInquiry},
  ];


  private $settlementState: FilterItemModel[] = [
    {name: 'ارسال درخواست', value: CooperationSettlementRequestStatusEnum.Pending},
    {name: 'درحال بررسی', value: CooperationSettlementRequestStatusEnum.InProgress},
    {name: 'انجام شده', value: CooperationSettlementRequestStatusEnum.Done},
    {name: 'رد شده', value: CooperationSettlementRequestStatusEnum.Rejected},
  ];

  private $completeInformation: FilterItemModel[] = [
    {name: 'FatherName', value: 1},
  ];

  private $sendStatus: FilterItemModel[] = [
    {name: 'درخواست شما با خطا مواجه شده‌است.', value: SendStatusEnum.InternalServerError},
    {name: 'عملیات با موفقیت انجام شد', value: SendStatusEnum.Success},
    {name: 'کلید وب سرویس نامعتبر است', value: SendStatusEnum.ApiKeyIsWrong},
    {name: 'کلید وب سرویس غیرفعال است', value: SendStatusEnum.ApiKeyIsInactive},
    {name: "کلید وب سرویس محدود به آی‌پی‌های تعریف شده می‌باشد.", value: SendStatusEnum.ApiKeyIsLimitedToIp},
    {name: "حساب کاربری غیرفعال است", value: SendStatusEnum.UserAccountIsDeactivated},
    {name: "حساب کاربری در حالت تعلیق قرار دارد", value: SendStatusEnum.UserAccountIsSuspended},
    {name: "به منظور استفاده از وب سرویس پلن خود را ارتقا دهید", value: SendStatusEnum.PlanItemAccessDenied},
    {name: "مقدار ارسالی پارامتر نادرست می‌باشد", value: SendStatusEnum.InvalidRequestModel},
    {name: "تعداد درخواست بیشتر از حد مجاز است", value: SendStatusEnum.RequestRateLimitExceeded},
    {name: "شماره خط نامعتبر میباشد", value: SendStatusEnum.LineNumberIsWrong},
    {name: "اعتبار کافی نمیباشد", value: SendStatusEnum.CreditNotEnough},
    {name: "درخواست شما دارای متن (های) خالی است", value: SendStatusEnum.NullOrEmptyMessageText},
    {name: "درخواست شما دارای موبایل (های) نادرست است", value: SendStatusEnum.MobileIsWrong},
    {name: "تعداد موبایل ها بیشتر از حد مجاز (100 عدد) میباشد", value: SendStatusEnum.TooManyMobileListCount},
    {name: "تعداد متن ها بیشتر از حد مجاز (100 عدد) میباشد", value: SendStatusEnum.TooManyMessageTextListCount},
    {name: "لیست موبایل ها خالی میباشد", value: SendStatusEnum.EmptyMobileList},
    {name: "لیست متن ها خالی میباشد", value: SendStatusEnum.EmptyMessageTextsList},
    {name: "زمان ارسال نامعتبر میباشد", value: SendStatusEnum.InvalidSendDateTime},
    {
      name: "تعداد شماره موبایل ها و تعداد متن ها برابر نیستند",
      value: SendStatusEnum.MobilesAndMessageTextsNotCompatible
    },
    {name: "با این شناسه ارسالی ثبت نشده است", value: SendStatusEnum.IdIsWrong},
    {name: "رکوردی برای حذف یافت نشد", value: SendStatusEnum.ScheduledMessagesNotFound},
    {name: "قالب یافت نشد", value: SendStatusEnum.TemplateNotFound},
    {
      name: "طول رشته مقدار پارامتر، بیش از حد مجاز (25 کاراکتر) میباشد",
      value: SendStatusEnum.LengthOfParameterValueIsLongerThanAllowed
    },
    {name: "شماره موبایل(ها) در لیست سیاه سامانه می‌باشند", value: SendStatusEnum.AllMobilesAreInBlackList},
    {
      name: "نام یک یا چند پارامتر مقداردهی نشده‌است. لطفا به بخش مستندات ارسال وریفای مراجعه فرمایید",
      value: SendStatusEnum.ParameterNameIsNullOrEmpty
    },
    {name: "متن ارسال شده مورد تایید نمی‌باشد", value: SendStatusEnum.InvalidFrequentMessageText},
    {name: "تعداد پیام ها بیشتر از حد مجاز میباشد", value: SendStatusEnum.MessageCountOutOfRange},
    {
      name: "به منظور استفاده از قالب‌ شخصی سازی شده پلن خود را ارتقا دهید",
      value: SendStatusEnum.CustomFastSendTemplatePlanLimitation
    },
  ];

  private $actionName: FilterItemModel[] = [
    {name: "ارسال وریفای", value: ActionNameEnum.VerifySend},
    {name: "ارسال گروهی", value: ActionNameEnum.BulkSend},
    {name: "ارسال نظیر به نظیر", value: ActionNameEnum.PeerToPeerSend},
    {name: "ارسال از طریق URL", value: ActionNameEnum.UrlSend},
    {name: "حذف ارسال زمانبندی شده", value: ActionNameEnum.RemoveScheduledMessages},
    {name: "گزارش پیامک (دریافت وضعیت)", value: ActionNameEnum.GetSentMessage},
    {name: "گزارش مجموعه ارسال", value: ActionNameEnum.GetPack},
    {name: "گزارش مجموعه ارسال‌های روز", value: ActionNameEnum.GetLivePacks},
    {name: "گزارش ارسال‌های روز", value: ActionNameEnum.GetSendLiveReport},
    {name: "گزارش ارسال‌های آرشیو شده", value: ActionNameEnum.GetSendArchiveLiveReport},
    {name: "گزارش تازه‌ترین پیامک‌های دریافتی", value: ActionNameEnum.GetReceiveLatestMessages},
    {name: "گزارش پیامک‌های دریافتی روز", value: ActionNameEnum.GetReceiveLiveReport},
    {name: "گزارش پیامک‌های دریافتی آرشیو شده", value: ActionNameEnum.GetReceiveArchiveReport},
    {name: "دریافت لیست خطوط", value: ActionNameEnum.GetUserLines},
    {name: "دریافت مقدار اعتبار فعلی", value: ActionNameEnum.GetUserCredit},
  ];

  private $context: FilterItemModel[] = [
    {name: "پیامک سفید", value: ContextEnum.PayamakSefid},
    {name: "پنل کاربری", value: ContextEnum.UserPanel},
  ];

  private $ApiEnvironment: FilterItemModel[] = [
    {name: "عملیاتی - Production", value: EnvironmentType.Production},
    {name: "تست - Sandbox", value: EnvironmentType.Sandbox},
  ];
}
