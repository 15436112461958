import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SystemGuard} from "@shared/guards/system.guard";
import {MaintenanceComponent} from "./extra-components/maintenance/maintenance.component";
import {C404Component} from "./extra-components/c404/c404.component";
import {AuthGuard} from "./auth/guards/auth.guard";
import {PrefixNumberGuard} from "@shared/guards/prefix-number.guard";

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./main/main.module').then(m => m.MainModule),
        canActivate: [PrefixNumberGuard, SystemGuard],
    },
    {
        path: 'auth',
        canActivate: [PrefixNumberGuard, AuthGuard],
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },

    {path: '404', component: C404Component},

    {path: 'maintenance', component: MaintenanceComponent},

    {path: '**', redirectTo: '404'}
];

@NgModule({
    imports: [RouterModule, RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 25],
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
