<form (submit)="onSubmit()" [formGroup]="modalForm" class="one-input-modal">
  <div class="tw-flex tw-flex-col tw-items-center tw-gap-3">
    <img [src]="svg ? svg : sharedService.EditSVG" alt="">
    <h1>{{modalHeader}}</h1>
  </div>

  <div class="modal-body text-center">
    <label>{{modalText}}</label>
    <input [maxLength]="(inputConf && inputConf.maxLength) ? inputConf.maxLength : 128"
           [placeholder]="(inputConf && inputConf.placeholder) ? inputConf.placeholder : ''"
           [type]="(inputConf && inputConf.type) ? inputConf.type : 'text'" formControlName="text">
  </div>

  <div class="modal-footer text-center">
    <button [disabled]="!modalForm.valid" class="btn success-btn" type="submit">
      {{modalConfirmButton ? modalConfirmButton : 'تایید'}}
    </button>
    <button (click)="ngbActiveModal.dismiss()"
            class="btn dark-btn mr-3">{{modalCancelButton ? modalCancelButton : 'بازگشت' }}</button>
  </div>
</form>
