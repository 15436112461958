<div class="template-modal">
  <div class="tw-flex tw-flex-col tw-items-center tw-gap-3">
    <img [src]="sharedService.QuestionSVG" alt="">
    <h1 class="my-3">{{title}}</h1>
  </div>

  <div class="modal-body text-center">
    <p class="text-center">{{description}}</p>
  </div>

  <div class="modal-footer text-center">

    <button (click)="onSubmit()" class="btn success-btn">
      {{modalConfirmButton ? modalConfirmButton : 'تایید'}}
    </button>
    <button (click)="ngbActiveModal.dismiss()" class="btn dark-btn mr-3">
      {{modalCancelButton ? modalCancelButton : 'اصلاح مجدد'}}
    </button>

  </div>
</div>

