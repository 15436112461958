<div class="tw-flex tw-flex-col tw-items-center tw-gap-3">

  <h5 class="red-text">جزئیات اپراتور های گیرنده</h5>
</div>
<div class="modal-footer text-center">
  <div class="text-center">
    <div class="text-center modal-table">

      <div class="modal-table__item" *ngFor="let operator of sharedService.operator">
        <div class="modal-table__item--header">{{operator.name}}</div>
        <div class="modal-table__item--value">{{data.willSendCountPerOperator[operator.value]}}</div>
      </div>

    </div>
  </div>
  <div class="text-center" dir="rtl">
    <ng-container *ngIf="!needConfirm">
      <button class="btn success-btn" (click)="activeModal.dismiss()">تایید</button>
    </ng-container>
    <ng-container *ngIf="needConfirm">
      <button class="btn success-btn" (click)="activeModal.close(true)">تایید</button>
      <button class="btn success-btn mr-3" (click)="activeModal.close(false)">تغییر مجدد</button>
    </ng-container>
  </div>
</div>

