<div class="template-modal">
  <div class="tw-flex tw-flex-col tw-items-center tw-gap-3">
    <img [src]="sharedService.QuestionSVG" alt="">
    <h1 class="my-3">{{title}}</h1>
  </div>

  <div class="modal-body">
    <p class="text-right mb-3" style="white-space: pre-line; line-height: 1.5rem">{{description}}</p>

    <app-checkbox
      (checkedChange)="checkboxValue = $event.target['checked']"
      [checked]="checkboxValue"
      id="agreedTermsCheckbox"
      label="با شرایط و قوانین استفاده از پیامک سفید موافقم">
    </app-checkbox>

  </div>

  <div class="modal-footer text-center">

    <button [disabled]="!checkboxValue" (click)="onSubmit()" class="btn success-btn">
      {{'تایید'}}
    </button>

  </div>
</div>

