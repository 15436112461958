<div class="tw-flex tw-flex-col tw-items-center tw-gap-3">
  <h5 class="red-text">جزئیات مخاطبان</h5>
</div>
<div class="modal-footer text-center">
  <div class="text-center">
    <div class="text-center modal-table">

      <div class="modal-table__item">
        <div class="modal-table__item--header">تعداد کل</div>
        <div class="modal-table__item--value">{{data.totalCount}}</div>
      </div>
      <div class="modal-table__item">
        <div class="modal-table__item--header">تعداد مخاطبان با شماره اشتباه</div>
        <div class="modal-table__item--value">{{data.wrongCount}}</div>
      </div>
      <div class="modal-table__item" *ngIf="data.distinctCount">
        <div class="modal-table__item--header">تعداد مخاطبان با شماره تکراری</div>
        <div class="modal-table__item--value">{{data.totalCount - (data.distinctCount + data.wrongCount)}}</div>
      </div>
      <div class="modal-table__item" *ngIf="data.distinctCount">
        <div class="modal-table__item--header">تعداد مخاطبان قابل ارسال</div>
        <div class="modal-table__item--value">{{data.distinctCount}}</div>
      </div>

    </div>
  </div>
  <app-error [haveSpace]="true" [text]="'مخاطبی برای ارسال وجود ندارد'" *ngIf="data.distinctCount === 0">
  </app-error>
  <div class="text-center" dir="rtl" *ngIf="!needConfirm">
    <button class="btn success-btn mr-2" (click)="activeModal.dismiss()">تایید</button>
  </div>
  <div class="text-center" dir="rtl" *ngIf="needConfirm">
    <ng-container *ngIf="data.distinctCount=== 0 || data.totalCount === data.wrongCount">
      <button class="btn success-btn mr-2" (click)="activeModal.dismiss()">تغییر مجدد</button>
    </ng-container>
    <ng-container *ngIf="data.distinctCount > 0 || data.totalCount > data.wrongCount">
      <button class="btn success-btn mr-2" (click)="submit()">تایید
      </button>
      <button class="btn  success-btn mr-2" (click)="activeModal.dismiss()">تغییر مجدد</button>
    </ng-container>
  </div>
</div>

